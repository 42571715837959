export default ({ inputId, inputName }) => ({
  // Props
  inputId,
  inputName,

  // State
  invalid: false,
  errorText: "",
  input: null,
  iti: null,
  maskPattern: "",
  nationalMode: true,
  placeholderNumberType: "MOBILE",

  // Handlers
  reset() {
    this.errorText = "";
    this.invalid = false;
  },
  getExampleNumber() {
    return window.intlTelInputUtils.getExampleNumber(
      this.iti.getSelectedCountryData().iso2,
      this.nationalMode,
      intlTelInputUtils.numberType[this.placeholderNumberType],
    );
  },
  generateMaskPattern(exampleNumber) {
    const startsWithZero = exampleNumber.startsWith("0");
    if (startsWithZero) {
      return "0" + exampleNumber.substring(1).replace(/\d/g, "9");
    } else {
      return exampleNumber.replace(/\d/g, "9");
    }
  },
  updateMask() {
    const example = this.getExampleNumber();
    if (example) {
      this.maskPattern = this.generateMaskPattern(example);
    }
  },
  handler(event) {
    this.reset();
    if (this.input.value.trim() && this.iti) {
      if (!this.iti.isValidNumber()) {
        this.invalid = true;
        const example = this.getExampleNumber();
        this.errorText = `Enter a valid phone number (e.g. ${example}).`;
        event.preventDefault();
        this.input.focus();
      }
    }
  },
  init() {
    this.input = document.querySelector(`#${this.inputId}`);
    this.iti = window.intlTelInput(this.input, {
      utilsScript:
        "https://fastly.jsdelivr.net/npm/intl-tel-input@20.3.0/build/js/utils.js",
      preferredCountries: [],
      separateDialCode: false,
      showFlags: true,
      nationalMode: this.nationalMode,
      hiddenInput: function (telInputName) {
        return { phone: inputName };
      },
      initialCountry: "auto",
      placeholderNumberType: this.placeholderNumberType,
      geoIpLookup: (callback) => {
        fetch("https://ipapi.co/json")
          .then((res) => res.json())
          .then((data) => callback(data.country_code))
          .catch(() => callback("us"));
      },
    });
    this.iti.promise.then(() => {
      this.updateMask();
      this.input.addEventListener("countrychange", () => {
        this.updateMask();
      });
    });
  },
});

function testPhoneNumberInput() {
  const iti = window.intlTelInputGlobals.getInstance(
    document.querySelector("#id_phone_number"),
  );
  const allCountries = window.intlTelInputGlobals.getCountryData();
  const form = document.querySelector("form");
  form.setAttribute("hx-swap", "none");
  form.first_name.value = "John";
  form.last_name.value = "Doe";
  form.email.value = "john.doe@example.com";

  allCountries.forEach((countryData, index) => {
    setTimeout(() => {
      // Set the country
      iti.setCountry(countryData.iso2);

      // Generate a valid phone number for this country
      const exampleNumber = intlTelInputUtils.getExampleNumber(
        countryData.iso2,
        true,
        intlTelInputUtils.numberType.MOBILE,
      );

      // Set the number in the input
      iti.setNumber(exampleNumber);

      // Submit the form
      htmx.trigger(form, "submit");

      // Log the action
      console.log(
        `Tested country: ${countryData.name}, Phone: ${exampleNumber}`,
      );
    }, 3000 * index);
  });
}
