export default () => {
  const beforeUnloadHandler = (event) => {
    event.preventDefault();
    event.returnValue = true;
  };
  const htmxBeforeRequestHandler = (event) => {
    // Determine if the event target is a navigation request.
    const isNavigationRequest =
      event.detail.boosted && event.target.tagName === "A";

    if (isNavigationRequest) {
      const isConfirmed = confirm(
        "You have unsaved changes. Are you sure you want to navigate away?",
      );
      if (!isConfirmed) {
        event.preventDefault(); // Prevent navigation
      }
    }
  };

  return {
    // State
    formData: JSON.parse(document.querySelector("#form_data").textContent),
    initialFormData: JSON.parse(
      document.querySelector("#form_data").textContent,
    ),

    // Methods
    init() {
      // Watch form data and add beforeUnloadHandler callback to beforeunload event when form state is dirty
      // This will prevent unintended data loss and provide better UX
      this.$watch("formData", () => {
        if (this.checkFormState(this.formData, this.initialFormData)) {
          window.addEventListener("beforeunload", beforeUnloadHandler);
          window.addEventListener(
            "htmx:beforeRequest",
            htmxBeforeRequestHandler,
          );
        } else {
          window.removeEventListener("beforeunload", beforeUnloadHandler);
          window.removeEventListener(
            "htmx:beforeRequest",
            htmxBeforeRequestHandler,
          );
        }
      });
    },
    destroy() {
      // Remove the beforeUnloadHandler callback from beforeunload when component lifecycle ends
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      window.removeEventListener(
        "htmx:beforeRequest",
        htmxBeforeRequestHandler,
      );
    },
    /**
     * Recursively checks if two objects (obj1 and obj2) are deeply equal.
     */
    isDeepEqual(obj1, obj2) {
      // Fast equality check for identical references or primitives
      if (obj1 === obj2) {
        return true;
      }
      // Check if either argument is not an object or is null
      if (
        typeof obj1 !== "object" ||
        obj1 === null ||
        typeof obj2 !== "object" ||
        obj2 === null
      ) {
        return false;
      }
      // Get the keys of both objects
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      // If the number of properties is different, objects are not equal
      if (keys1.length !== keys2.length) {
        return false;
      }
      // Check each property in obj1 to ensure it exists and is equal in obj2
      for (let key of keys1) {
        if (!keys2.includes(key)) {
          return false;
        }
        // Special handling for functions and deeper dive into nested objects
        if (
          typeof obj1[key] === "function" ||
          typeof obj2[key] === "function"
        ) {
          if (obj1[key].toString() !== obj2[key].toString()) {
            return false;
          }
        } else if (!this.isDeepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }

      return true;
    },
    /**
     * Determines the dirty state of the form by comparing formData and initialFormData.
     */
    checkFormState(formData, initialFormData) {
      const isDirty = !this.isDeepEqual(formData, initialFormData);
      return isDirty;
    },
  };
};
