import Cookies from "js-cookie";

export default ({ rejectReasons, sortableUpdateUrl }) => ({
  // Props
  rejectReasons,
  sortableUpdateUrl,

  // State
  isModalOpen: false,
  rejectReasonApplicationCount: 0,
  selectedRejectReason: null,
  deletedRejectReasonId: null,
  sortableDeleteUrl: null,
  requestInProgress: false,
  editingSortableItemId: null,

  init() {
    // Watch `editingSortableItemId` value and notify others of its state
    // This will help the Sortable instances to disable themselves based
    // on `editingSortableItemId` value.
    this.$watch("editingSortableItemId", (value) => {
      this.$dispatch("editingSortableItem", { value })
    });
  },

  updateSortableItem() {
    let formDiv = document.getElementById(
      "editingForm-" + this.editingSortableItemId,
    );
    let inputs = [
      ...formDiv.getElementsByTagName("input"),
      ...formDiv.getElementsByTagName("select"),
    ];
    let data = {};

    inputs.forEach((input) => {
      data[input.name] = input.value;
      data["id"] = input.parentElement.parentElement.dataset.itemId;
    });

    // send request with ajax
    fetch(this.sortableUpdateUrl, {
      method: "POST",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        location.reload();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },

  getRejectReasonApplicationCount(rejectReasonUrl) {
    let applicationCount = fetch(rejectReasonUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        return data.data.application_count;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    return applicationCount;
  },
  executeDeleteRejectReason(deletedRejectReasonId, newRejectReasonId) {
    fetch(this.sortableDeleteUrl, {
      method: "DELETE",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        deletedRejectReasonId: deletedRejectReasonId,
        newRejectReasonId: newRejectReasonId,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  async deleteRejectReason(
    rejectReasonUrl,
    sortableDeleteUrl,
    deletedRejectReasonId,
  ) {
    let applicationCount =
      await this.getRejectReasonApplicationCount(rejectReasonUrl);
    this.sortableDeleteUrl = sortableDeleteUrl;

    if (applicationCount === 0) {
      document.getElementById("hiddenBtn-" + deletedRejectReasonId).click();
    } else {
      this.isModalOpen = true;
      this.rejectReasonApplicationCount = applicationCount;
      this.deletedRejectReasonId = deletedRejectReasonId;
    }
  },
  confirmRejectReasonSelection() {
    if (this.selectedRejectReason) {
      this.executeDeleteRejectReason(
        this.deletedRejectReasonId,
        this.selectedRejectReason,
      );
      this.isModalOpen = false;
      this.selectedRejectReason = null;
      this.deletedRejectReasonId = null;
      location.reload();
    } else {
      alert("Please select a reject reason.");
    }
  },
});
