export default ({
  type,
  subject,
  body,
  templates,
  name,
  url,
  actionButtonText,
  editorId,
}) => ({
  // Props
  type,
  subject,
  body,
  templates,
  name,
  url,
  actionButtonText,
  editorId,

  // State
  lastFocusedElement: null,

  // Models
  template: "",

  // Placeholder functionality
  placeholders: [
    { text: "Candidate Name", value: "candidate_name", preview: "Susan" },
    { text: "Candidate Surname", value: "candidate_surname", preview: "Doe" },
    {
      text: "Position Title",
      value: "position_name",
      preview: "Lead Researcher",
    },
  ],

  insertPlaceholderBody(placeholderValue) {
    // Define the value to be inserted
    let insertValue = `{{ ${placeholderValue} }}`;
    let editor = window[editorId];

    // Insert the placeholder at the current cursor position
    editor.model.change((writer) => {
      const insertPosition = editor.model.document.selection.getFirstPosition();

      editor.model.insertContent(
        writer.createText(insertValue),
        insertPosition,
      );
    });
  },

  insertPlaceholderSubject(input, placeholderValue) {
    let insertValue = `{{ ${placeholderValue} }}`;

    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const beforeCursor = input.value.substring(0, startPos);
    const afterCursor = input.value.substring(endPos);

    // If the cursor is next to a character, add a space.
    let prefixSpace = "";
    let suffixSpace = "";
    if (startPos > 0 && beforeCursor.at(-1) !== " ") {
      prefixSpace = " ";
    }
    if (afterCursor.at(0) !== " ") {
      suffixSpace = " ";
    }
    insertValue = prefixSpace + insertValue + suffixSpace;
    input.value = beforeCursor + insertValue + afterCursor;

    // Update the cursor position after inserting text
    const newPosition = startPos + insertValue.length;
    input.setSelectionRange(newPosition, newPosition);

    // Update AlpineJS's internal state for the input/textarea
    this.subject = input.value;
  },

  insertPlaceholder(input, placeholderValue) {
    if (input && input.id === "subject") {
      this.insertPlaceholderSubject(input, placeholderValue);
    } else if (input && input.role === "textbox") {
      this.insertPlaceholderBody(placeholderValue);
    }
  },

  updateTemplate() {
    if (this.template === "0") {
      this.subject = "";
      this.body = "";
    } else {
      fetch(`/api/templates/${this.template}/`)
        .then((response) => {
          // Check if the request was successful
          if (!response.ok) {
            throw new Error("Response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          this.subject = data.subject;
          this.body = data.html_body;
        })
        .catch((error) => {
          console.error(
            "There was a problem with the fetch operation:",
            error.message,
          );
        });
    }
  },
});
