/** Filter component
 *
 * Alpine properties
 * $popover
 * $store: filterFields
 *
 */
export default ({
  fieldName, // string
  fieldId, // string
  type, // string
  url, // string
  target, // string
  initialOpenState, // boolean
  queryParam, // callable. returns string
  isEmpty, // callable. returns boolean
  displayText, // callable. returns string
  initFromURL, // callable
  cleanValue, // callable
  labelText, // callable. returns string
}) => ({
  // Props
  fieldName,
  fieldId,
  type,
  url,
  target: target || "#candidates_table_wrapper",
  initialOpenState,

  // Unimplemented methods. Should be implemented later.
  queryParam,
  isEmpty, // Returns true if input field value is empty
  displayText, // Returns displayed text
  initFromURL,
  cleanValue, // Cleans the field value
  labelText, // Returns label text

  // State
  panelLocked: false,
  requestInProgress: false,
  timeoutId: null,

  // Common methods.
  hide() {
    this.__wrapper.classList.add("hidden");
  },
  remove() {
    this.__wrapper.remove();
  },
  initializeButton() {
    this.initialOpenState && this.$popover.open();
    this.$watch("$popover.isOpen", (value) => {
      if (!value & this.isEmpty()) {
        this.$store.filterFields.remove(this.fieldId);
        this.remove();
      }
      if (!value && this.panelLocked) {
        this.$popover.open();
      }
    });
  },

  // Event listeners
  openFilterPanelListener: {
    /** Opens the panel on `open-filter-panel` event if `fieldId` matches with the event detail.
     *
     */
    ["x-on:open-filter-panel.window"]() {
      this.$event.detail === this.fieldId && this.$popover.open();
    },
  },

  get __wrapper() {
    return this.$root.parentElement;
  },
});
