import ClassicEditor from "../../vendor/ckeditor/ckeditor.js";

export default ({ initialValue, placeholder, editorId }) => ({
  input: initialValue ? initialValue : "",
  placeholder: placeholder ? placeholder : "",
  editorId: editorId ? editorId : "editor",
  init() {
    ClassicEditor.create(document.getElementById(editorId), {
      placeholder: this.placeholder,
    })
      .then((editor) => {
        window[editorId] = editor;

        // Set initial conent
        editor.setData(this.input);

        // Listen change event to update the input state
        editor.model.document.on("change:data", () => {
          this.updateInputFromEditor(editor);
        });

        // Listen input's change events to update editor
        this.$watch("input", (newValue) => {
          this.updateEditorFromInput(editor, newValue);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  updateInputFromEditor(editor) {
    const editorContent = editor.getData();
    if (this.input !== editorContent) {
      this.input = editorContent;
    }
  },
  updateEditorFromInput(editor, newValue) {
    const editorContent = editor.getData();
    if (newValue !== editorContent) {
      editor.setData(newValue);
    }
  },
});
