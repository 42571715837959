const dayjs = require("dayjs");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var isoWeek = require("dayjs/plugin/isoWeek");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);

export default () => {
  return {
    inProgress: false,
    currentDate: dayjs(),
    today() {
      return dayjs();
    },
    setDate(date) {
      this.currentDate = dayjs(date);
    },
    nextMonth() {
      this.currentDate = this.currentDate.add(1, "month");
    },
    previousMonth() {
      this.currentDate = this.currentDate.subtract(1, "month");
    },
    getDaysArray() {
      let startDay = this.currentDate.startOf("month").startOf("isoWeek");
      let endDay = this.currentDate.endOf("month").endOf("isoWeek");
      let days = [];
      for (let day = startDay; day.isBefore(endDay); day = day.add(1, "day")) {
        days.push({
          date: day.format("YYYY-MM-DD"),
          number: day.format("D"),
          isCurrentMonth: day.month() === this.currentDate.month(),
          isCurrentDay:
            day.format("YYYY-MM-DD") === this.currentDate.format("YYYY-MM-DD"),
          isToday: day.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD"),
          isStartOfMonth: day.date() === 1,
          isEndOfMonth: day.date() === day.daysInMonth(),
          isTopLeftDay: day.isSame(startDay, "day"),
          isTopRightDay: day.isSame(startDay.add(6, "day"), "day"),
          isBottomLeftDay: day.isSame(endDay.subtract(6, "day"), "day"),
          isBottomRightDay: day.isSame(endDay, "day"),
        });
      }
      return days;
    },
    unauthenticatedInterviewers: [],
    // calendar data format: {id: string, events: array}
    calendars: [],
    filteredEvents(calendarData) {
      return calendarData.events.filter((event) => {
        const eventStart = this.convertToLocalTime(
          event.when.start_time,
          event.when.start_timezone,
        );
        const eventEnd = this.convertToLocalTime(
          event.when.end_time,
          event.when.end_timezone,
        );
        return (
          eventStart.isSame(this.currentDate, "day") ||
          eventEnd.isSame(this.currentDate, "day")
        );
      });
    },
    getEventClasses(event) {
      return "relative mt-px flex";
    },
    getGridRow(event) {
      const localTime = this.convertToLocalTime(
        event.when.start_time,
        event.when.start_timezone,
      );
      const hour = localTime.hour();
      const minute = localTime.minute();
      return hour * 12 + Math.floor(minute / 5) + 2; // +2 for initial offset in grid rows
    },
    getGridSpan(event) {
      const startTime = this.convertToLocalTime(
        event.when.start_time,
        event.when.start_timezone,
      );
      const endTime = this.convertToLocalTime(
        event.when.end_time,
        event.when.end_timezone,
      );
      const duration = endTime.diff(startTime, "minute");
      return Math.ceil(duration / 5); // Each grid row represents 5 minutes
    },
    formatTime(event) {
      const localTime = this.convertToLocalTime(
        event.when.start_time,
        event.when.start_timezone,
      );
      return localTime.format("h:mm A");
    },
    getDateTime(event) {
      return this.convertToLocalTime(
        event.when.start_time,
        event.when.start_timezone,
      ).toISOString();
    },
    convertToLocalTime(unixTime, timezone) {
      return dayjs.unix(unixTime).tz(timezone);
    },
    isEven(index) {
      return index % 2 === 0;
    },
    /*
     * Fetches calendar events for the given interviewer and adds them to the calendars array
     * If the interviewer is not authenticated, adds the email to the unauthenticatedInterviewers array
     *
     * @param {{ email: string, name: string, id: string }} interviewer - interviewer object
     */
    fetchCalendars(interviewer) {
      this.inProgress = true;
      fetch(
        `/scheduling/calendars/${interviewer.id}/events/${this.currentDate.startOf("day").unix()}/`,
      )
        .then(async (response) => {
          this.inProgress = false;
          if (!response.ok) {
            const data = await response.json();
            throw data;
          }
          return response.json();
        })
        .then((data) => {
          // push data to calendars array
          // check if the interviewer is already in the calendars array
          // if so, update the events array
          const index = this.calendars.findIndex(
            (calendar) => calendar.id === interviewer.email,
          );
          if (index === -1) {
            this.calendars.push({ id: interviewer.email, events: data.events });
          } else {
            this.calendars[index].events = data.events;
          }
        })
        .catch((error) => {
          // If error is 400 and error.type is "grant-needed", push the email to unauthenticatedInterviewers
          if (error?.error?.type === "grant-needed") {
            this.unauthenticatedInterviewers.push(interviewer.email);
          } else {
            // Report error to sentry
            try {
              throw new Error("Unexpected Nylas error", error);
            } catch (err) {
              Sentry.captureException(err);
            }
          }
        });
    },
    init() {
      this.$watch("interviewers, currentDate", (value) => {
        // For each interviewer, fetch calender events and add returned data to calendars
        // Clean up calendars and unauthenticatedInterviewers arrays before fetching new data
        this.calendars = [];
        this.unauthenticatedInterviewers = [];
        for (const interviewer of value) {
          this.fetchCalendars(interviewer);
        }
      });
    },
  };
};
