export default () => ({
  search: "",
  searchUrl: "",
  isCommandPaletteOpen: false,
  closingCallback: () => {},
  handleOpen(e) {
    if (e.detail.search_url) {
      this.searchUrl = e.detail.search_url;
      this.search = e.detail.search || "";
      this.isCommandPaletteOpen = true;
      this.closingCallback = e.detail.closingCallback || this.closingCallback;
    }
    if (this.search) {
      this.searchData();
    }
  },
  handleClose(e) {
    this.isCommandPaletteOpen = false;
    this.closingCallback();
  },
  updateURL() {
    let baseUrl = this.searchUrl;
    // Prepend a placeholder domain to form a full URL for parsing
    const placeholderDomain = "https://placeholder.com";
    let url;

    try {
      url = new URL(placeholderDomain + baseUrl);
    } catch (e) {
      console.error("Invalid URL provided.");
      return;
    }

    // Set or update the "search" query parameter
    if (this.search) {
      url.searchParams.set("search", this.search);
    } else {
      url.searchParams.delete("search");
    }

    // Remove the placeholder domain from the final URL string
    return url.toString().replace(placeholderDomain, "");
  },
  searchData() {
    let newUrl = this.updateURL();
    htmx.ajax("GET", newUrl, {
      target: "#candidate_palette_results",
      swap: "innerHTML",
    });
  },
});
