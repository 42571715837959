// Sentry
if (process.env.NODE_ENV === "production") {
  import("@sentry/browser").then((Sentry) => {
    Sentry.init({
      environment: "production",
      dsn: process.env.NODE_SENTRY_DSN,
      sendDefaultPii: true,
      // Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,
      // Performance
      tracesSampleRate: 0.1,
      tracePropagationTargets: ["localhost", /^https:\/\/app\.gethirex\.com/],
      // Profiling
      profilesSampleRate: 0.1,
      integrations: [
        new Sentry.browserProfilingIntegration(),
        new Sentry.browserTracingIntegration(),
        new Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        new Sentry.captureConsoleIntegration(),
        new Sentry.contextLinesIntegration(),
        new Sentry.httpClientIntegration(),
      ],
    });
    window.Sentry = Sentry;
  });
} else {
  import("@sentry/browser").then((Sentry) => {
    Sentry.init({
      environment: "development",
      dsn: process.env.NODE_SENTRY_DSN,
    });
    window.Sentry = Sentry;
  });
}

// Apexcharts
import ApexCharts from "apexcharts";
window.ApexCharts = ApexCharts;

// Alpine
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import mask from "@alpinejs/mask";
import morph from "@alpinejs/morph";
import persist from "@alpinejs/persist";
import sort from "@alpinejs/sort";
import ui from "@alpinejs/ui";
import Alpine from "alpinejs";

import options_editor from "./components/application_form_fields/options_editor.js";
import command_palette from "./components/command_palette/command_palette.js";
import email_editor from "./components/email_editor/email_editor.js";
import filter from "./components/filter/filter.js";
import combobox from "./components/form_inputs/combobox.js";
import phone_input from "./components/form_inputs/phone_input.js";
import job_form from "./components/job_posting_wizard/job_form.js";
import workflow from "./components/job_posting_wizard/workflow.js";
import organization_sortable from "./components/organizations/organization_sortable.js";
import text_editor from "./components/text_editor/text_editor.js";
import availability_calendar from "./pages/scheduling/components/availability_calendar.js";

Alpine.plugin(focus);
Alpine.plugin(ui);
Alpine.plugin(morph);
Alpine.plugin(mask);
Alpine.plugin(collapse);
Alpine.plugin(persist);
Alpine.plugin(sort);
Alpine.plugin(intersect);

// Global stores
Alpine.store("filterFields", {
  fields: [],

  add(field) {
    this.fields.push(field);
  },
  remove(field) {
    const index = this.fields.indexOf(field);
    this.fields.splice(index, 1);
  },
  has(field) {
    return this.fields.includes(field) ? true : false;
  },
  flush() {
    this.fields = [];
  },
});

window.Alpine = Alpine;

window.Alpine.data("email_editor", email_editor);
window.Alpine.data("text_editor", text_editor);
window.Alpine.data("combobox", combobox);
window.Alpine.data("workflow", workflow);
window.Alpine.data("organization_sortable", organization_sortable);
window.Alpine.data("job_form", job_form);
window.Alpine.data("options_editor", options_editor);
window.Alpine.data("phone_input", phone_input);
window.Alpine.data("filter", filter);
window.Alpine.data("command_palette", command_palette);
window.Alpine.data("availability_calendar", availability_calendar);

window.Alpine.start();

// Flowbite
import "flowbite";
