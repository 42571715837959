import Cookies from "js-cookie";

export default ({ stagesData, url, pipeline, job }) => ({
  // Props
  stagesData,
  url,
  pipeline,
  job,
  errors: {},

  // State
  editingStageId: null,
  newStageAdded: false,
  isModalOpen: false,
  selectedStage: null,
  deletedStageId: null,
  stageApplicationCount: null,
  requestInProgress: false,

  // Handlers
  addStage(stageType) {
    if (!this.newStageAdded) {
      const stageTypeIndex = this.stagesData.findIndex(
        (typeData) => typeData.stage_type.value === stageType,
      );

      const newStage = {
        id: 0,
        color: "#94A3B8",
        name: "",
        description: "",
        order: null,
        stage_type: stageType,
      };

      // Add the new stage to the end of its group
      this.stagesData[stageTypeIndex].stages.push(newStage);

      this.newStageAdded = true;
      this.editingStageId = 0;

      // Re-assign order for every stage
      this.reassignOrders();
    }
  },
  reassignOrders() {
    let order = 1;
    this.stagesData.forEach((stageTypeData) => {
      stageTypeData.stages.forEach((stage) => {
        stage.order = order;
        order++;
      });
    });
  },
  removeNewStage() {
    this.stagesData.forEach((stageTypeData) => {
      const newStageIndex = stageTypeData.stages.findIndex(
        (stage) => stage.id === 0,
      );
      if (newStageIndex !== -1) {
        stageTypeData.stages.splice(newStageIndex, 1);
        this.newStageAdded = false;
      }
    });
  },
  formHandler(stageData) {
    this.requestInProgress = true;
    // Form validation
    if (!stageData.name) {
      stageData.errors = {
        name: ["This field is required."],
      };
      return;
    }
    // Add pipeline and stage_type to each stage
    this.stagesData.forEach((group) => {
      group.stages.forEach((stage) => {
        stage.pipeline = this.pipeline;
        stage.stage_type = group.stage_type.value;
      });
    });
    // Convert the entire stagesData state into a JSON string
    const payload = JSON.stringify({
      pipeline: this.pipeline,
      stagesData: this.stagesData,
    });

    fetch(this.url, {
      method: "POST",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json",
      },
      body: payload,
    })
      .then(async (response) => {
        this.requestInProgress = false;
        if (!response.ok) {
          const errorData = await response.json();
          const error = new Error(response.statusText);
          error.errors = errorData;
          throw error;
        }
        return response.json();
      })
      .then((data) => {
        this.stagesData = data.data; // Update the state
        this.editingStageId = null;
        this.newStageAdded = false;
      })
      .catch((error) => {
        console.error("Error:", error);
        stageData.errors = error.errors.errors;
      });
  },
  getStageApplicationCount(stageId) {
    let applicationCount = fetch(
      `/jobs/${this.job}/stages/${stageId}/application_count/`,
      {
        method: "GET",
      },
    )
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        return data.data.application_count;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    return applicationCount;
  },
  deleteStage(stageId) {
    this.getStageApplicationCount(stageId).then((data) => {
      if (data === 0) {
        this.executeDeleteStage(stageId, null);
      } else {
        this.deletedStageId = stageId;
        this.stageApplicationCount = data;
        this.isModalOpen = true;
      }
    });
  },
  executeDeleteStage(stageId, newStageId) {
    this.requestInProgress = true;
    fetch(url, {
      method: "DELETE",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: stageId,
        newStageId: newStageId,
      }),
    })
      .then(async (response) => {
        this.requestInProgress = false;
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        this.stagesData = data.data; // Update the state
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  confirmStageSelection() {
    if (this.selectedStage) {
      this.executeDeleteStage(this.deletedStageId, this.selectedStage);
      this.isModalOpen = false;
      this.selectedStage = null;
      this.deletedStageId = null;
    } else {
      alert("Please select a stage.");
    }
  },
  getNewOrderingDataFromHtml() {
    let newOrderingData = [];
    const baseStages = document.querySelectorAll(".sortable");
    let counter = 0;
    baseStages.forEach((stage, index) => {
      let currentStage = {
        order: counter + 1,
        stage_type: {
          value: stage.dataset.stageTypeId,
          label: stage.dataset.stageTypeLabel,
        },
        stages: []
      }
      stage.querySelectorAll(".stage").forEach((stage, stageIndex) => {

        let stageData = {
          id: stage.dataset.stageId,
          order: counter + 1,
          name: stage.dataset.stageName,
          color: stage.dataset.colorCode,
          description: stage.dataset.stageDescription,
          pipeline: this.pipeline,
          stage_type: stage.dataset.stageType,
        }
        currentStage.stages.push(stageData);
        counter++;
      });
      newOrderingData.push(currentStage);
    });
    return newOrderingData;
  },
  updateOrder() {
    this.requestInProgress = true;
    this.stagesData = this.getNewOrderingDataFromHtml();
    const payload = JSON.stringify({
      pipeline: this.pipeline,
      stagesData: this.stagesData
    });

    fetch(this.url, {
      method: "POST",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json",
      },
      body: payload,
    })
      .then(async (response) => {
        this.requestInProgress = false;
        if (!response.ok) {
          const errorData = await response.json();
          const error = new Error(response.statusText);
          error.errors = errorData;
          throw error;
        }
        return response.json();
      })
      .then((data) => {
        this.stagesData = data.data; // Update the state
      }).then(
    )
      .catch((error) => {
        console.error("Error:", error);
      });
  },

});
