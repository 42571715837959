export default ({ choices }) => ({
  // Props
  choices,

  // State
  query: "",
  selected: [],
  get filteredChoices() {
    return this.query === ""
      ? this.choices
      : this.choices.filter((choice) => {
          return choice.name.toLowerCase().includes(this.query.toLowerCase());
        });
  },
  remove(choice) {
    this.selected = this.selected.filter((i) => i !== choice);
  },
});
