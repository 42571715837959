export default ({ options }) => ({
  options,
  newOption: "",
  addOption() {
    if (this.newOption.trim() === "") return;
    const uniqueId =
      this.slugify(this.newOption) + "-" + Math.floor(Math.random() * 10000);
    const newOptionObj = {
      text: this.newOption,
      value: uniqueId,
      order: this.options.length + 1,
    };
    this.options.push(newOptionObj);
    this.newOption = "";
  },
  deleteOption(index) {
    // Event filtering
    if (document.activeElement != this.$el) return;
    this.options.splice(index, 1);
    this.options.forEach((option, idx) => (option.order = idx + 1));
  },
  dragStart(event, index) {
    event.dataTransfer.setData("optionIndex", index);
  },
  drop(event, dropIndex) {
    const dragIndex = event.dataTransfer.getData("optionIndex");
    const draggedItem = this.options.splice(dragIndex, 1)[0];
    this.options.splice(dropIndex, 0, draggedItem);
    this.options.forEach((option, idx) => (option.order = idx + 1));
  },
  slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  },
  serializedOptions() {
    return JSON.stringify(this.options);
  },
});
